/** These are theme helpers that can be used in browser.
 * There are seaparated from theme_helper.ts which contains node specific helpers
 * which cannot run in the browser. */

import getPalette from 'tailwindcss-palette-generator'

export const theme = {
  hexToRgb(hex: string): string {
    hex = hex.replace('#', '')
    const match = hex.match(/.{1,2}/g)
    if (!match) {
      throw new Error('Invalid hex color')
    }
    return match.map((x) => Number.parseInt(x, 16)).join(' ')
  },
  getPalette: getPalette,
}
